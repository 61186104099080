import React from "react";
import axios from "axios";

import { useGeneral } from "../../contexts/general-context";
import { useModals } from "../../contexts/modals-context";
import { dictionary } from "../../dictionary";
import { apiDomain } from "../../config";
import { useNavigate } from "react-router-dom";

function FactoryReset() {
    const { language } = useGeneral();
    const { factoryReset, setFactoryReset, setLoader, setAlert } = useModals();
    const navigate = useNavigate();

    return (
        <div id="factory-reset-modal" className="modal" style={{ display: factoryReset.show ? 'block' : 'none' }}>
            <div className="modal-content alert confirm">
                <div>
                    <h3 className="modal-header">{dictionary.config.factoryReset.header[language]}</h3>
                </div>
                <div>
                    <p>{dictionary.config.factoryReset.content[language]}</p>
                </div>
                <div className="row">
                    <button
                        className="navbutton modal-button"
                        onClick={async () => {
                            try {
                                setFactoryReset({ show: false });
                                setLoader(true);
                                await axios.post(`${apiDomain}/api/cfg`, {
                                    "cfgFactory": {
                                        "MODE": "FULL",
                                    }
                                })
                                setLoader(false);
                                navigate('/');
                                window.location.reload();
                            } catch (error) {
                                setLoader(false);
                                console.error('Error:', error);
                                setAlert({
                                    show: true,
                                    title: dictionary.modals.alert.factoryReset.error.header[language],
                                    content: dictionary.modals.alert.factoryReset.error.content[language],
                                    func: () => { }
                                });
                            }
                        }}
                    >
                        {dictionary.config.factoryReset.yes[language]}
                    </button>
                    <button
                        className="navbutton modal-button"
                        onClick={() => setFactoryReset({ show: false })}
                    >{dictionary.config.factoryReset.no[language]}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FactoryReset;
