import axios from "axios";
import { apiDomain } from "../config";

export const formatDate = (date = new Date()) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    let day = String(date.getDate()).padStart(2, '0');
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const generateCSV = async (data) => {
    let time = new Date();
    try {
        const response = await axios(`${apiDomain}/api/tiempo`);
        time = new Date(response.data['TIEMPO']);
    } catch (error) {
        console.log('Error: ', error);
    }

    // Convert data to CSV format
    const csvContent = data.map(row => row.join(';')).join('\n');

    // Create a Blob object with CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary anchor element and trigger a download
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `reporte-${formatDate(time)}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error('Your browser does not support downloading files.');
    }
}