import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";

import { dictionary } from "../dictionary";

import XRedIcon from "../images/xred.svg";
import SaveIcon from "../images/save.svg";
import { editInput, fetchInputBynumberAndSign } from "../services/inputs";
import { useFormik } from "formik";




const SignInputForm = () => {
    const [input, setInput] = useState({ enable: false, name: '', type: 0, camera: '' })
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const navigate = useNavigate();
    const { id, inputNumber } = useParams();

    const formik = useFormik({
        initialValues: input,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setLoader(true);
                await editInput(values.id, {
                    enable: values.enable,
                    name: values.name,
                    type: Number(values.type),
                    camera: values.camera
                })
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.success.header[language],
                    content: dictionary.modals.alert.default.success.content[language],
                    func: () => navigate(`/settings/signs/${id}/inputs/${inputNumber}/manage`)
                });
            } catch (error) {
                console.error('Error: ', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const data = await fetchInputBynumberAndSign(inputNumber, id);
            setInput(data)
            document.title = `Entradas ${data.name}`;
            setLoader(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Entrada {input.name}</h2>
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='input-group'>
                            <span>
                                <input
                                    className="input-input"
                                    type="checkbox"
                                    checked={formik.values.enable}
                                    onChange={(e) => formik.setFieldValue('enable', e.target.checked)}
                                />
                                {dictionary.config.inputs.enable[language]}
                            </span>
                        </div>
                        <div className="input-group">
                            <label htmlFor='username'>
                                Nombre de entrada
                            </label>
                            <input
                                id='username'
                                className="input users-input"
                                name="username"
                                type="text"
                                placeholder='Nombre de entrada'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor='type'>
                                Tipo de entrada
                            </label>
                            <select
                                id='type'
                                className="input users-input"
                                name='type'
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.type}
                            >
                                {dictionary.config.inputs.type.select[language].map(((el, index) =>
                                    el && <option key={index} value={index}>{el}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-group'>
                            <label htmlFor="camera">
                                Camara
                            </label>
                            <input
                                id="camera"
                                className="input"
                                placeholder='URL de camara'
                                name="camera"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.camera}
                            />
                        </div>
                        <button
                            className='button'
                            type="submit"
                        >
                            <img className='icon' src={SaveIcon} alt={dictionary.common.save[language]} />
                            {dictionary.common.save[language]}
                        </button>
                    </form>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${id}/inputs/${inputNumber}/manage`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default SignInputForm;
