import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";

import { dictionary } from "../dictionary";

import { deleteSing, fetchSing } from "../services/sings";
import NavButton from "../components/NavButton";

import ConfigIcon from "../images/config.svg";
import PaperIcon from "../images/paper.png";
import cameraIcon from "../images/camera.svg";
import InputsIcon from "../images/layers.svg";
import evacuationIcon from "../images/evacuation.svg";
import simulacrumIcon from "../images/simulacrum.svg";
import XRedIcon from "../images/xred.svg";




const SignManage = () => {
    const [sign, setSign] = useState({ name: '', mac: '', registered: false });
    const { language } = useGeneral();
    const { setLoader } = useModals();
    const navigate = useNavigate();
    const { id } = useParams();

    const deleteSign = async () => {
        await deleteSing(sign.id);
        navigate('/settings/signs');
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const data = await fetchSing(id)
            setSign(data);
            document.title = `Administrar ${data.name}`;
            setLoader(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Administrar {sign.name}</h2>
                </div>
                <div className='config-content'>
                    <NavButton imgSrc={ConfigIcon} text="General"
                        onClick={() => navigate(`/settings/signs/${sign.id}/general`)}
                    />
                    <NavButton imgSrc={PaperIcon} text="Roles de evacuacion"
                        onClick={() => navigate(`/settings/signs/${sign.id}/evac-role`)}
                    />
                    <NavButton imgSrc={cameraIcon} text="Camaras"
                        onClick={() => navigate(`/settings/signs/${sign.id}/cameras`)}
                    />
                    <NavButton imgSrc={InputsIcon} text="Entradas"
                        onClick={() => navigate(`/settings/signs/${sign.id}/inputs`, { state: { sign: sign } })}
                    />
                    <NavButton imgSrc={evacuationIcon} text="Evacuacion"
                        onClick={() => navigate(`/settings/signs/${sign.id}/evac`)}
                    />
                    <NavButton imgSrc={simulacrumIcon} text="Simulacro"
                        onClick={() => navigate(`/settings/signs/${sign.id}/simu`)}
                    />
                    <NavButton imgSrc={XRedIcon} text="Eliminar"
                        onClick={() => deleteSign()}
                    />
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate('/settings/signs')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default SignManage;
