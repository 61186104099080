import axios from "axios"
import { apiDomain } from "../config"

export const fetchTelegram = async () => {
    const response = await axios.get(`${apiDomain}/api/telegram`);
    return (response.data);
}

export const postTelegram = async (telegramBody) => {
    const response = await axios.put(`${apiDomain}/api/telegram`, telegramBody)
    return (response.data);
}