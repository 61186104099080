import React, { useEffect, useRef, useState } from "react";

import XIcon from "../../images/x.svg";
import deleteIcon from "../../images/delete.svg";
import cornerDownLeftIcon from "../../images/cornerdownleft.svg";
import { useModals } from "../../contexts/modals-context";
import axios from "axios";
import { apiDomain } from "../../config";


const Keypad = () => {
    const { keypad, setKeypad } = useModals();
    const [users, setUsers] = useState([]);

    const codeInputRef = useRef();
    const fetchUsers = async () => {
        const response = await axios.get(`${apiDomain}/api/users`);
        setUsers(response.data);
    }

    const makeLogin = async (id, password) => {
        const response = await axios.post(`${apiDomain}/api/auth/login`, { id: keypad.userSelected, password: codeInputRef.current.value })

        if (response.status === 200) {
            setKeypad({ ...keypad, show: false, userSelected: users[0].id, code: '' })
            window.localStorage.setItem('token', response.data.token);
            keypad.func();
        } else {
            codeInputRef.current.style.borderColor = "#ff6666";
            setTimeout(() => {
                codeInputRef.current.style.borderColor = "#fafafa";
                setKeypad({ ...keypad, code: '' })
            }, 250)
        }
    }

    useEffect(() => {
        if (users.length > 0) {
            setKeypad({ ...keypad, userSelected: users[0].id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    useEffect(() => {
        if (keypad.show) {
            fetchUsers();
        } else {
            setKeypad({ ...keypad, code: '' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keypad.show])

    return (
        <div className="modal" style={{ display: keypad.show ? 'block' : 'none' }}>
            <div className="modal-content">
                <span className="close" onClick={() => setKeypad({ ...keypad, show: false, userSelected: users[0].id, code: '' })}>
                    <img src={XIcon} alt='' />
                </span>
                <div className="keyboard">
                    <select type="text" className="keypad-field" onChange={(current) => setKeypad({ ...keypad, userSelected: Number(current.target.value) })} value={keypad.userSelected}>
                        {users.map((user) => <option key={user.id} value={user.id}>{user.username}</option>)}
                    </select>
                    <input ref={codeInputRef} type="text" className="keypad-field" readOnly value={keypad.code} />
                    <div className="row">
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '1' })}>1</div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '2' })}>2</div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '3' })}>3</div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code.slice(0, -1) })}><img src={deleteIcon} alt='' /></div>
                    </div>
                    <div className="row">
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '4' })}>4</div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '5' })}>5</div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '6' })}>6</div>
                        <div className="key" onClick={() => makeLogin()}><img src={cornerDownLeftIcon} alt='' /></div>
                    </div>
                    <div className="row">
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '7' })}>7</div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '8' })}>8</div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '9' })}>9</div>
                        <div className="key hidden"></div>
                    </div>
                    <div className="row">
                        <div className="key hidden"></div>
                        <div className="key" onClick={() => setKeypad({ ...keypad, code: keypad.code + '0' })}>0</div>
                        <div className="key hidden"></div>
                        <div className="key hidden"></div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Keypad;