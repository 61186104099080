import React from "react";
import axios from "axios";

import { useModals } from "../../contexts/modals-context";
import { useGeneral } from "../../contexts/general-context";
import { useTimers } from "../../contexts/timers-context";
import { dictionary } from "../../dictionary";
import { apiDomain } from "../../config";

const CommandLaunch = () => {
    const { language } = useGeneral();
    const { setAlert, setLoader, command, setCommand } = useModals();
    const { commandsTimers, setCommandsTimers } = useTimers();

    const sendCommand = async (number, time) => {
        console.log(number, time);
        try {
            setLoader(true);
            await axios.post(`${apiDomain}/api/cfg`, {
                "cmdOutputs": {
                    number: number,
                    time: time
                }
            });
            const commandsList = commandsTimers.map((command) =>
                ({ number: command.number, name: command.name, timer: command.number === number ? time : command.timer })
            )
            setCommandsTimers(commandsList);
            setCommand({ show: false, number: null, title: '' });
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.commands.success.header[language],
                content: dictionary.modals.alert.commands.success.content[language],
                func: () => { }
            });
        } catch (error) {
            console.error('Error:', error);
            setCommand({ show: false, number: null, title: '' });
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.commands.error.header[language],
                content: dictionary.modals.alert.commands.error.content[language],
                func: () => { }
            });
        }
    }




    return (
        <div className="modal" style={{ display: command.show ? 'block' : 'none' }}>
            <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                    <h3 className="modal-header">{command.title}</h3>
                </div>
                <div className="keyboard">
                    <div className="row">
                        <div className="key commands" onClick={() => sendCommand(command.number, 5)}>5s</div>
                        <div className="key commands" onClick={() => sendCommand(command.number, 10)}>10s</div>
                        <div className="key commands" onClick={() => sendCommand(command.number, 20)}>20s</div>
                    </div>
                    <div className="row">
                        <div className="key commands" onClick={() => sendCommand(command.number, 40)}>40s</div>
                        <div className="key commands" onClick={() => sendCommand(command.number, 60)}>60s</div>
                        <div className="key commands" onClick={() => sendCommand(command.number, 90)}>90s</div>
                    </div>
                    <div className="row commands-opts">
                        <div className="key commands direct" onClick={() => sendCommand(command.number, 0)}>{dictionary.modals.commands.direct[language]}</div>
                        <div className="key commands" onClick={() => setCommand({ show: false, number: null, title: '' })}>{dictionary.modals.commands.cancel[language]}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommandLaunch;