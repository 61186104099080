import React from "react";


const NavButton = ({ imgSrc = '', text = '', onClick = () => { } }) => (
    <button className='navbutton' onClick={onClick}>
        {imgSrc !== '' && <img src={imgSrc} alt={text} />}
        <span>{text}</span>
    </button>
);

export default NavButton;