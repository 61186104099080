import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";
import { fetchInputBynumberAndSign } from "../services/inputs";
import { fetchCamerasInput } from "../services/cameras";
import NavButton from "../components/NavButton";

import { dictionary } from "../dictionary";
import XRedIcon from "../images/xred.svg";
import CameraIcon from "../images/camera.svg";

const InputListCameras = () => {
    const [cameras, setCameras] = useState([]);
    const [input, setInput] = useState([]);
    const { language } = useGeneral();
    const { setLoader } = useModals();
    const navigate = useNavigate();
    const { id, inputNumber } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const inputData = await fetchInputBynumberAndSign(inputNumber,id);
            setInput(inputData);
            const cameras =await fetchCamerasInput(inputData.id);
            setCameras(cameras);
            document.title = `Camaras ${inputData.name}`;
            setLoader(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Lista camaras {input.name}</h2>
                </div>
                <div className='config-content'>
                    {cameras.map((camera) =>
                        <NavButton
                            imgSrc={CameraIcon}
                            text={`Camara ${camera.number}`}
                            onClick={() => navigate(`/settings/signs/${id}/inputs/${inputNumber}/cameras/${camera.number}`, { state: { camId: camera.id, redirectUrl: `/settings/signs/${id}/inputs/${inputNumber}/cameras` } })}
                        />
                    )}
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${id}/inputs/${inputNumber}/general`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default InputListCameras;
