import axios from "axios"
import { apiDomain } from "../config"
import { formatDate } from "../utils"

export const fetchGeneral = async () => {
    const response = await axios.get(`${apiDomain}/api/general`);
    return ({
        name: response.data.name,
        manuals: response.data.manuals,
        location: response.data.location,
        notes: response.data.notes
    });
}

export const fetchDate = async () => {
    const response = await axios.get(`${apiDomain}/api/general/datetime`);
    return (formatDate(new Date(response.data.date)));
}

export const setDate = async (date, time) => {
    const response = await axios.put(`${apiDomain}/api/general/datetime`, { date: date, time: time});
    return (response.data);
}

export const updateLanguage = async (language) => {
    const response = await axios.put(`${apiDomain}/api/general/language`, { language: language });
    return (response.data);
}

export const postGeneral = async (generalBody) => {
    const response = await axios.put(`${apiDomain}/api/general`, generalBody);
    return (response.data);
}
