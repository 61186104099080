import React from "react";

const ItemMenu = ({image, text, func}) => {
    return (
        <button className="navbutton" onClick={func}>
            <img src={image} alt={text} style={{ maxHeight: '32px', maxWidth: '32px' }} />
            <span>{text}</span>
        </button>
    );
}

export default ItemMenu;