import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-width: 2px;
    border-radius: 4px;
    cursor: pointer;
    img {
        height: 28px;
        width: auto;
        padding: 4px;
    }
`;

const Button = ({ imgSrc, text = '', onclick = () => { }, ...props }) => (
    <StyledButton
        type="button"
        onClick={onclick}
        {...props}
    >
        {imgSrc && <img src={imgSrc} alt={text} />}
        {text}
    </StyledButton>
);

export default Button;