import React, { useEffect, useState } from "react";

import { dictionary } from "../dictionary";

import bellIcon from '../images/bell.svg';
import bellGreyIcon from '../images/bell-grey.svg';
import faultIcon from '../images/fault.svg';
import faultGreyIcon from '../images/fault-grey.svg';
import disconnectIcon from '../images/disconnect.svg';
import sirenGrey from '../images/siren-grey.svg';
import sirenRedEvacIcon from '../images/siren-red-evac.svg';
import sirenRedSimuIcon from '../images/siren-red-simu.svg';
import sirenRedTestIcon from '../images/siren-red-test.svg';
import networkFaultIcon from '../images/networkfault.svg';
import networkNormalIcon from '../images/networknormal.svg';
import lockCloseIcon from '../images/lockclose.svg';
import lock1Icon from '../images/lockopen1.svg';
import lock2Icon from '../images/lockopen2.svg';
import { useGeneral } from "../contexts/general-context";
import { useWebSocket } from "../contexts/ws-context";

const NavBar = () => {
    const token = window.localStorage.getItem('token');

    const [alarm, setAlarm] = useState(false);
    const [disconnect, setDisconnect] = useState(false);
    const [playing, setPlaying] = useState('');
    const [fault, setFault] = useState(false);
    const [network, setNetwork] = useState(false);
    const [lock, setLock] = useState(0);

    const { language, generalData, localTime } = useGeneral();
    const { status, message } = useWebSocket();

    const sirenIcon = () => {
        if (playing === 'emergency') {
            return (<img className='icon' src={sirenRedEvacIcon} alt={dictionary.statusBar.playing.evac[language]} />);
        } else if (playing === 'simulacrum') {
            return (<img className='icon' src={sirenRedSimuIcon} alt={dictionary.statusBar.playing.simu[language]} />);
        } else if (playing === 'test') {
            return (<img className='icon' src={sirenRedTestIcon} alt={dictionary.statusBar.playing.test[language]} />);
        } else {
            return (<img className='icon' src={sirenGrey} alt={dictionary.statusBar.playing.off[language]} />);
        }
    }

    const lockIcon = () => {
        if (lock === 2) {
            return <img className='icon' src={lock2Icon} alt={dictionary.statusBar.lock.lock[language]} />
        } else if (lock === 1) {
            return <img className='icon' src={lock1Icon} alt={dictionary.statusBar.lock.level1[language]} />
        } else {
            return <img className='icon' src={lockCloseIcon} alt={dictionary.statusBar.lock.level2[language]} />
        }

    }

    const formatClock = () => {
        const timeDate = new Date(localTime);

        const hours = String(timeDate.getHours()).padStart(2, "0");
        const minutes = String(timeDate.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    }

    useEffect(() => {
        const fetchBarStatus = async () => {
            try {
                setAlarm(false);
                setFault(false);
                setPlaying('');
                setDisconnect(false);
                setNetwork(true);
                setLock(0);
            } catch (error) {
                console.log('Error: ', error)
            }
        }
        fetchBarStatus();
    }, [])

    useEffect(() =>{
        if (token) {
            setLock(2)
        } else {
            setLock(0)
        }
    },[token])

    useEffect(() => {
        let alarmStatus = false;
        let faultStatus = false;
        let disconnectStatus = false;
        let playingMessage = '';
        for (let signMAC in message) {
            //check sign disconnect
            if (!message[signMAC].enable) {
                disconnectStatus = true;
            }

            // verify playing msg
            if (message[signMAC].message === 'emergency') {
                playingMessage = message[signMAC].message;
            } else if ((message[signMAC].message === 'simulacrum') && (playingMessage !== 'emergency')) {
                playingMessage = message[signMAC].message;
            } else if ((message[signMAC].message === 'test') && ((playingMessage !== 'emergency') || (playingMessage !== 'simulacrum'))) {
                playingMessage = message[signMAC].message;
            }


            //check inputs states
            let signInputs = message[signMAC].inputs;
            for (let input in signInputs) {
                if (
                    (signInputs[input].status === 2) ||
                    (signInputs[input].status === 3) ||
                    (signInputs[input].status === 6) ||
                    (signInputs[input].status === 7) ||
                    (signInputs[input].status === 8) ||
                    (signInputs[input].status === 9)
                ) {
                    alarmStatus = true;
                } else if (
                    (signInputs[input].status === 1) ||
                    (signInputs[input].status === 4) ||
                    (signInputs[input].status === 5)
                ) {
                    faultStatus = true;
                }
            }
        }
        setAlarm(alarmStatus);
        setFault(faultStatus);
        setDisconnect(disconnectStatus);
        setPlaying(playingMessage);

    }, [message])

    useEffect(() => setNetwork(status === 'CONNECTED'), [status])

    return (
        <header>
            <nav className="status-bar">
                <div className='icon-container left'>
                    {alarm ?
                        <img className='icon' src={bellIcon} alt={dictionary.statusBar.alarm[language]} />
                        :
                        <img className='icon' src={bellGreyIcon} alt={dictionary.statusBar.alarm[language]} />
                    }
                    {fault ?
                        <img className='icon' src={faultIcon} alt={dictionary.statusBar.fault[language]} />
                        :
                        <img className='icon' src={faultGreyIcon} alt={dictionary.statusBar.fault[language]} />
                    }
                    {disconnect && <img className='icon' src={disconnectIcon} alt={dictionary.statusBar.disconnect[language]} />}
                    {sirenIcon()}
                </div>
                <h1 id="status-bar-name" className="nombre">{generalData.name}</h1>
                <div className='icon-container right'>
                    {(network) ?
                        <img className='icon' src={networkNormalIcon} alt={dictionary.statusBar.network.normal[language]} />
                        :
                        <img className='icon' src={networkFaultIcon} alt={dictionary.statusBar.network.fault[language]} />
                    }
                    {lockIcon()}
                    <span className='clock'>{formatClock()}</span>
                </div>
            </nav>
        </header>
    );
}

export default NavBar;