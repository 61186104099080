import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";
import { createUser, deleteUser, fetchUser, updateUser } from "../services/users";
import { dictionary, tabNames } from "../dictionary";

import SaveIcon from "../images/save.svg";
import XRedIcon from "../images/xred.svg";
import { useFormik } from "formik";


function UsersForm() {
    const [user, setUser] = useState({ username: '', levelAccess: 1, code: '' })
    const navigate = useNavigate();
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const { id } = useParams();

    const formik = useFormik({
        initialValues: user,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setLoader(true);
                let inputsIncomplete = false;

                if ((values.username === '') || (values.code === '')) {
                    inputsIncomplete = true;
                }

                if (inputsIncomplete) {
                    setLoader(false);
                    setAlert({
                        show: true,
                        title: dictionary.modals.alert.accessCodes.incomplete.header[language],
                        content: dictionary.modals.alert.accessCodes.incomplete.content[language],
                        func: () => { }
                    });
                } else {
                    if (id === 'create') {
                        await createUser(values);
                    } else {
                        await updateUser(id, values);
                    }
                    setLoader(false);
                    setAlert({
                        show: true,
                        title: dictionary.modals.alert.default.success.header[language],
                        content: dictionary.modals.alert.default.success.content[language],
                        func: () => navigate('/settings/users')
                    });
                }


            } catch (error) {
                console.error('Error: ', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    });

    const deleteUserBtn = async () => {
        try {
            setLoader(true);
            await deleteUser(id);
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.accessCodes.delete.header[language],
                content: dictionary.modals.alert.accessCodes.delete.content[language],
                func: () => navigate('/settings/users')
            });
        } catch (error) {
            console.error('Error: ', error);
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.default.error.header[language],
                content: dictionary.modals.alert.default.error.content[language],
                func: () => { }
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                setUser(await fetchUser(id));
                setLoader(false);
            } catch (error) {
                console.error('Error:', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.users.GETError.header[language],
                    content: dictionary.modals.alert.users.GETError.content[language],
                    func: () => { }
                });
            }
        }
        if (id === 'create') {
            document.title = `${tabNames.accessCodes[language]} | ${dictionary.config.accessCodes.newUser[language]}`;
        } else {
            document.title = `${tabNames.accessCodes[language]} | ${dictionary.config.accessCodes.user[language]}${id}`;
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>
                        {(id === 'create') ?
                            dictionary.config.accessCodes.newUser[language]
                            :
                            `${dictionary.config.accessCodes.user[language]}${id}`
                        }

                    </h2>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="input-group">
                        <label htmlFor='username'>
                            {dictionary.config.accessCodes.username.label[language]}
                        </label>
                        <input
                            id='username'
                            className="input users-input"
                            name="username"
                            type="text"
                            placeholder={dictionary.config.accessCodes.username.input[language]}
                            onChange={formik.handleChange}
                            value={formik.values.username}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor='levelAccess'>
                            {dictionary.config.accessCodes.accessLevel.label[language]}
                        </label>
                        <select
                            id='levelAccess'
                            className="input users-input"
                            name='levelAccess'
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.levelAccess}
                        >
                            {[1, 2].map((el => <option key={el} value={el}>{el}</option>))}
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor='code'>
                            {dictionary.config.accessCodes.accessCode.label[language]}
                        </label>
                        <input
                            id='code'
                            className="input users-input"
                            name="code"
                            type="text"
                            placeholder={dictionary.config.accessCodes.accessCode.input[language]}
                            onChange={formik.handleChange}
                            value={formik.values.code}
                        />
                    </div>
                    <div className="row">
                        <button
                            className='button'
                            type="submit"
                        >
                            <img className='icon' src={SaveIcon} alt={dictionary.common.save[language]} />
                            {dictionary.common.save[language]}
                        </button>
                        {(id !== 'create') &&
                            <button
                                className="button"
                                type="button"
                                onClick={() => deleteUserBtn()}
                            >
                                <img src={XRedIcon} alt={dictionary.common.delete[language]} className="icon" />
                                {dictionary.common.delete[language]}
                            </button>
                        }

                    </div>
                </form>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate('/settings/users')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>

    );
}

export default UsersForm;
