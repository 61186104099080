import axios from "axios"
import { apiDomain } from "../config"

export const fetchSings = async () => {
    const response = await axios.get(`${apiDomain}/api/sings`)
    return (response.data);
}

export const fetchSing = async (id) => {
    const response = await axios.get(`${apiDomain}/api/sings/${id}`)
    return (response.data);
}

export const editSings = async (id, signBody) => {
    const response = await axios.put(`${apiDomain}/api/sings/${id}`, signBody)
    return (response.data);
}

export const fetchEvacRole = async (id) => {
    const response = await axios.get(`${apiDomain}/api/sings/${id}/evac-role`)
    return (response.data);
}

export const updateEvacRole = async (id, signBody) => {
    const response = await axios.put(`${apiDomain}/api/sings/${id}/evac-role`, signBody)
    return (response.data);
}

export const fetchEvac = async (id) => {
    const response = await axios.get(`${apiDomain}/api/sings/${id}/evac`)
    return (response.data);
}

export const updateEvac = async (id, signBody) => {
    const response = await axios.put(`${apiDomain}/api/sings/${id}/evac`, signBody)
    return (response.data);
}

export const fetchSimu = async (id) => {
    const response = await axios.get(`${apiDomain}/api/sings/${id}/simu`)
    return (response.data);
}

export const updateSimu = async (id, signBody) => {
    const response = await axios.put(`${apiDomain}/api/sings/${id}/simu`, signBody)
    return (response.data);
}

export const deleteSing = async (id) => {
    const response = await axios.delete(`${apiDomain}/api/sings/${id}`)
    return (response.data);
}