import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";
import { dictionary, tabNames } from "../dictionary";

import SaveIcon from "../images/save.svg";
import XRedIcon from "../images/xred.svg";
import { fetchTelegram, postTelegram } from "../services/telegram";

function Telegram() {
    const [telegram, setTelegram] = useState({
        enable: false,
        token: '',
        chatID: ''
    });
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: telegram,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setLoader(true)
                postTelegram(values);
                setLoader(false)
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.success.header[language],
                    content: dictionary.modals.alert.default.success.content[language],
                    func: () => navigate('/settings')
                });
            } catch (error) {
                console.error('Error:', error);
                setLoader(false)
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                setTelegram(await fetchTelegram());
                setLoader(false);
            } catch (error) {
                console.error('Error:', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.telegram.GETError.header[language],
                    content: dictionary.modals.alert.telegram.GETError.content[language],
                    func: () => { }
                });
            }
        }
        document.title = tabNames.telegram[language];
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>{tabNames.telegram[language]}</h2>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div>
                        <div className='input-group'>
                            <span>
                                <input
                                    id="enable"
                                    className="input"
                                    name="enable"
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    checked={formik.values.enable}
                                />
                                {dictionary.config.lines.enable[language]}
                            </span>
                        </div>
                        <div className='input-group'>
                            <label htmlFor="token">
                                {dictionary.config.telegram.token.label[language]}
                            </label>
                            <input
                                id="token"
                                className="input"
                                placeholder={dictionary.config.telegram.token.input[language]}
                                name="token"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.token}
                            />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="chat-id">{dictionary.config.telegram.chatID.label[language]}</label>
                            <input
                                id="chatID"
                                className="input"
                                placeholder={dictionary.config.telegram.chatID.input[language]}
                                name="chatID"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.chatID}
                            />
                        </div>
                        <button
                            className='button'
                            type="submit"
                        >
                            <img src={SaveIcon} alt="" className='icon' />
                            {dictionary.common.save[language]}
                        </button>
                    </div>
                </form>
            </main>
            <footer className="row">
                <button className='navbutton' onClick={() => navigate('/settings')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default Telegram;
