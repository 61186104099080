import React from "react";
import { useModals } from "../../contexts/modals-context";
import { dictionary } from "../../dictionary";
import { useGeneral } from "../../contexts/general-context";


const Loader = () => {
    const { language } = useGeneral();
    const { loader } = useModals();

    return (
        <div className="modal" style={{ display: loader ? 'block' : 'none' }}>
            <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div>
                    <h3 className="modal-header">{dictionary.modals.loader.header[language]}</h3>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }} >
                    <div style={{ width: '48px' }}>
                        <div className="loader big"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;