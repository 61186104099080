import React from "react";
import { useModals } from "../../contexts/modals-context";


const Alert = () => {
    const { alert, setAlert } = useModals();

    const onClick = () => {
        alert.func()
        setAlert({ show: false, title: '', content: '', func: () => { } });
    }

    return (
        <div className="modal" style={{ display: alert.show ? 'block' : 'none' }}>
            <div className="modal-content alert">
                <div>
                    <div>
                        <h3 className="modal-header">{alert.title}</h3>
                    </div>
                    <div>
                        <p>{alert.content}</p>
                    </div>
                </div>
                <div className="row" style={{ justifyContent: 'center' }}>
                    <button className="navbutton modal-button" onClick={onClick}>OK</button>
                </div>
            </div>
        </div>
    );
}

export default Alert;