import axios from "axios"
import { apiDomain } from "../config"

export const fetchUsers = async () => {
    const response = await axios.get(`${apiDomain}/api/users`)
    return (response.data);
}

export const fetchUser = async (id) => {
    const response = await axios.get(`${apiDomain}/api/users/${id}`)
    return (response.data);
}

export const createUser = async (userBody) => {
    const response = await axios.post(`${apiDomain}/api/users`, userBody)
    return (response.data);
}

export const updateUser = async (id, userBody) => {
    const response = await axios.put(`${apiDomain}/api/users/${id}`, userBody)
    return (response.data);
}

export const deleteUser = async (id) => {
    const response = await axios.delete(`${apiDomain}/api/users/${id}`)
    return (response.data);
}

export const postUsers = async (usersBody) => {
    const response = await axios.post(`${apiDomain}/api/users`, usersBody)
    return (response.data);
}
