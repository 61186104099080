import axios from "axios"
import { apiDomain } from "../config"

export const fetchInputs = async (sign) => {
    const response = await axios.get(`${apiDomain}/api/inputs?sign=${sign}`)
    return (response.data);
}

export const fetchInput = async (id) => {
    const response = await axios.get(`${apiDomain}/api/inputs/${id}`)
    return (response.data);
}

export const fetchInputBynumberAndSign = async (number, sign) => {
    const response = await axios.get(`${apiDomain}/api/inputs/${number}?sign=${sign}`)
    return (response.data);
}

export const editInput = async (id, inputBody) => {
    const response = await axios.put(`${apiDomain}/api/inputs/${id}`, inputBody)
    return (response.data);
}
