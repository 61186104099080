import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";

import { dictionary, tabNames } from "../dictionary";

import SpainIcon from "../images/spain.png";
import USAIcon from "../images/usa.png";
import BrazilIcon from "../images/brazil.png";
import ItalyIcon from "../images/italy.png";
import GoogleMapsIcon from "../images/googlemaps.svg";
import SaveIcon from "../images/save.svg";
import XRedIcon from "../images/xred.svg";
import { fetchDate, setDate, updateLanguage, fetchGeneral, postGeneral } from "../services/general";
import { useFormik } from "formik";
import { formatDate } from "../utils";





function General() {
    const [general, setGeneral] = useState({ name: '', manuals: '', location: '', notes: '' });
    const [dateChanged, setDateChanged] = useState(false);
    const [datetime, setDatetime] = useState(null);

    const { language, setLanguage } = useGeneral();
    const { setAlert, setLoader } = useModals();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: general,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                if (dateChanged) {
                    const dateSplited = datetime.split(' ')
                    setDate(dateSplited[0], dateSplited[1])
                }
                setLoader(true);
                await postGeneral(values);
                setDateChanged(false);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.success.header[language],
                    content: dictionary.modals.alert.default.success.content[language],
                    func: () => {
                        navigate('/settings');
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                setLoader(false)
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                setGeneral(await fetchGeneral());
                setDatetime(await fetchDate());
                setLoader(false);
            } catch (error) {
                console.error('Error:', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.general.GETError.header[language],
                    content: dictionary.modals.alert.general.GETError.content[language],
                    func: () => { }
                });
            }

        }
        document.title = tabNames.general[language];
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeLanguage = async (languageSelected) => {
        await updateLanguage(languageSelected)
        setLanguage(languageSelected);
        window.localStorage.setItem('language', languageSelected);
        setAlert({
            show: true,
            title: dictionary.modals.language.header[language],
            content: dictionary.modals.language.content[language],
            func: () => { }
        });
    }
    return (
        <>

            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>{tabNames.general[language]}</h2>
                </div>
                <div>
                    <div className="row">
                        <button
                            className='navbutton'
                            onClick={() => changeLanguage('es')}
                        >
                            <img src={SpainIcon} alt="" />
                        </button>
                        <button
                            className='navbutton'
                            onClick={() => changeLanguage('en')}
                        >
                            <img src={USAIcon} alt="" />
                        </button>
                        <button
                            className='navbutton'
                            onClick={() => changeLanguage('pt')}
                        >
                            <img src={BrazilIcon} alt="" />
                        </button>
                        <button
                            className='navbutton'
                            onClick={() => changeLanguage('it')}
                        >
                            <img src={ItalyIcon} alt="" />
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='input-group'>
                            <label htmlFor="name">
                                {dictionary.config.general.installationName.label[language]}
                            </label>
                            <input
                                id="name"
                                className="input"
                                placeholder={dictionary.config.general.installationName.input[language]}
                                name="name"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="manuals`">
                                {dictionary.config.general.manualsURL.label[language]}
                            </label>
                            <input
                                id="manuals"
                                className="input"
                                placeholder={dictionary.config.general.manualsURL.input[language]}
                                name="manuals"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.manuals}
                            />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="location">
                                {dictionary.config.general.location.label[language]}
                            </label>
                            <div className="input-icon">
                                <input
                                    id="location"
                                    className="input"
                                    placeholder={dictionary.config.general.location.input[language]}
                                    name="location"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.location}
                                />
                                <span>
                                    <a href={`https://www.google.com.ar/maps/place/${general.location.replaceAll(' ', '+')}`} target='_blank' rel='noreferrer'>
                                        <img className="icon" src={GoogleMapsIcon} alt="" />
                                    </a>
                                </span>
                            </div>
                        </div>

                        <div className='input-group'>
                            <label htmlFor="datetime">
                                {dictionary.config.general.datetime.label[language]}
                            </label>

                            <input
                                id="datetime"
                                className="input"
                                placeholder={dictionary.config.general.datetime.label[language]}
                                name="datetime"
                                type="datetime-local"
                                onChange={(e) => {
                                    setDateChanged(true);
                                    setDatetime(formatDate(new Date(e.target.value)))
                                }}
                                value={formatDate(new Date(datetime))}
                            />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="notes">{dictionary.config.general.notes.label[language]}</label>
                            <textarea
                                id="notes"
                                type="text"
                                name="notes"
                                className="input"
                                rows={6}
                                cols={30}
                                placeholder={dictionary.config.general.notes.input[language]}
                                onChange={formik.handleChange}
                                value={formik.values.notes}
                            />
                        </div>
                        <button
                            className='button'
                            type="submit"
                        >
                            <img src={SaveIcon} alt="" className='icon' />
                            {dictionary.common.save[language]}
                        </button>
                    </form>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate('/settings')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default General;
