import axios from "axios"

import { apiDomain } from "../config"

export const emergencyCommand = async (mac) => {
    const response = await axios.patch(`${apiDomain}/api/commands/emergency`, { mac: mac ? mac : '*' });
    return (response.data);
}

export const simulacrumCommand = async (mac) => {
    const response = await axios.patch(`${apiDomain}/api/commands/simulacrum`, { mac: mac ? mac : '*' });
    return (response.data);
}

export const testCommand = async (mac) => {
    const response = await axios.patch(`${apiDomain}/api/commands/test`, { mac: mac });
    return (response.data);
}

export const resetCommand = async () => {
    const response = await axios.patch(`${apiDomain}/api/commands/reset`, {});
    return (response.data);
}

export const acknowledgeCommand = async () => {
    const response = await axios.patch(`${apiDomain}/api/commands/acknowledge`, {});
    return (response.data);
}
