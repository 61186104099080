import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ItemMenu from "../components/ItemMenu";
import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";
import { dictionary, tabNames } from "../dictionary";

import ConfigIcon from "../images/config.svg";
import LaversIcon from "../images/lavers.svg";
import SignIcon from "../images/sign.svg";
import UsersIcon from "../images/users.svg";
import TestIcon from "../images/test.svg";
import TelegramIcon from "../images/telegramlogo.svg"
import ReportsIcon from "../images/reports.svg";
import ExportIcon from "../images/export.svg";
import ResetIcon from "../images/reset.svg";
import XRedIcon from "../images/xred.svg";

function Settings() {
    const { language } = useGeneral();
    const { setFactoryReset } = useModals();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = tabNames.config[language];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <main>
            <div className='main-header'>
                <h2 className='main-header-title-dark'>{tabNames.config[language]}</h2>
            </div>
            <div id="config-content" className='config-content'>
                {[
                    { image: ConfigIcon, text: dictionary.config.menu.general[language], function: () => navigate('/settings/general') },
                    { image: LaversIcon, text: dictionary.config.menu.commands[language], function: () => navigate('/settings/commands', { state: '/settings' }) },
                    { image: SignIcon, text: 'Carteles', function: () => navigate('/settings/signs') },
                    { image: TestIcon, text: 'Test', function: () => navigate('/settings/test') },
                    { image: UsersIcon, text: dictionary.config.menu.accessCodes[language], function: () => navigate('/settings/users') },
                    { image: TelegramIcon, text: dictionary.config.menu.telegram[language], function: () => navigate('/settings/telegram') },
                    { image: ReportsIcon, text: dictionary.config.menu.reports[language], function: () => navigate('/settings/reports') },
                    // { image: ExportIcon, text: dictionary.config.menu.backupAndRestore[language], function: () => navigate('/settings/backup') },
                    { image: ResetIcon, text: dictionary.config.menu.factoryReset[language], function: () => { setFactoryReset({ show: true }); } },
                ].map((item, index) =>
                    <ItemMenu
                        key={index}
                        image={item.image}
                        text={item.text}
                        func={item.function}
                    />
                )}
            </div>
        </main>
        <footer className="row">
            <button className='navbutton' onClick={() => {
                window.localStorage.removeItem('token');
                navigate('/');
            }}>
                <img src={XRedIcon} alt='' />
                <span>{dictionary.common.close[language]}</span>
            </button>
        </footer>
    </>

    );
}

export default Settings;
