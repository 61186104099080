import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";
import { ModalsProvider } from './contexts/modals-context';
import { GeneralProvider } from './contexts/general-context';
import { TimersProvider } from './contexts/timers-context';
import App from './App';
import Keypad from './components/modals/Keypad';
import Alert from './components/modals/Alert';
import Loader from './components/modals/Loader';
import './index.css';
import CommandLaunch from './components/modals/CommandLaunch';
import RestoreModal from './components/modals/RestoreModal';
import FactoryReset from './components/modals/FactoryReset';
import { WebSocketProvider } from './contexts/ws-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WebSocketProvider>
        <GeneralProvider>
          <ModalsProvider>
            <TimersProvider>
              <App />
              <Keypad />
              <Alert />
              <Loader />
              <CommandLaunch />
              <RestoreModal />
              <FactoryReset />
            </TimersProvider>
          </ModalsProvider>
        </GeneralProvider>
      </WebSocketProvider>
    </BrowserRouter>
  </React.StrictMode>
);
