import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NavButton from "../components/NavButton";
import LineBar from "../components/LineBar";
import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";
import { dictionary, tabNames } from "../dictionary";
import { acknowledgeCommand, emergencyCommand, resetCommand, simulacrumCommand, testCommand } from "../services/commands";

import checkIcon from "../images/check.svg";
import resetIcon from "../images/reset.svg";
import evacuationIcon from "../images/evacuation.svg";
import simulacrumIcon from "../images/simulacrum.svg";
import testIcon from "../images/test.svg";
import configIcon from "../images/config.svg";
import { useWebSocket } from "../contexts/ws-context";
import { fetchAuthByRfid } from "../services/auth";

function Main() {
    const [signs, setSigns] = useState([]);
    const [generalBar, setGeneralBar] = useState(null);
    const { message } = useWebSocket();

    const { language } = useGeneral();
    const { keypad, setKeypad, setAlert, setLoader } = useModals();

    const navigate = useNavigate();

    const areAllMessagesEqual = (sings) => {
        let firstMessage = null;

        for (const key in sings) {
            if (sings.hasOwnProperty(key)) {
                const message = sings[key].message;

                if (firstMessage === null) {
                    firstMessage = message;
                } else if (message !== firstMessage) {
                    return null;
                }
            }
        }

        return firstMessage;
    }

    const handleCommandAuth = async (command = () => { }, accessRequired = 2) => {
        try {
            const isLocal = window.location.hostname === 'localhost';
            if (isLocal) {
                const isAuthByRfid = await fetchAuthByRfid();
                if (isAuthByRfid) {
                    command();
                    return;
                }
            }
            setKeypad({
                ...keypad,
                show: true,
                func: () => command(),
                accessRequired: accessRequired
            })

        } catch (error) {
            console.log(error);
        }
    }

    const evacuation = async () => {
        try {
            setLoader(true);
            await emergencyCommand('*');
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.evacuation.success.header[language],
                content: dictionary.modals.alert.evacuation.success.content[language],
                func: () => { }
            });
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.evacuation.error.header[language],
                content: dictionary.modals.alert.evacuation.error.content[language],
                func: () => { }
            });
        }
    }

    const simulacrum = async () => {
        try {
            setLoader(true);
            await simulacrumCommand('*');
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.simulacrum.success.header[language],
                content: dictionary.modals.alert.simulacrum.success.content[language],
                func: () => { }
            });
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.simulacrum.error.header[language],
                content: dictionary.modals.alert.simulacrum.error.content[language],
                func: () => { }
            });
        }
    }

    const test = async () => {
        try {
            setLoader(true);
            await testCommand('*');
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.test.success.header[language],
                content: dictionary.modals.alert.test.success.content[language],
                func: () => { }
            });
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.test.error.header[language],
                content: dictionary.modals.alert.test.error.content[language],
                func: () => { }
            });
        }
    }

    const reset = async () => {
        try {
            setLoader(true);
            await resetCommand();
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.reset.success.header[language],
                content: dictionary.modals.alert.reset.success.content[language],
                func: () => { }
            });
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.reset.error.header[language],
                content: dictionary.modals.alert.reset.error.content[language],
                func: () => { }
            });
        }
    }

    const acknowledge = async () => {
        try {
            setLoader(true);
            await acknowledgeCommand();
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.acknowledge.success.header[language],
                content: dictionary.modals.alert.acknowledge.success.content[language],
                func: () => { }
            });
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.acknowledge.error.header[language],
                content: dictionary.modals.alert.acknowledge.error.content[language],
                func: () => { }
            });
        }
    }


    useEffect(() => {
        document.title = tabNames.start[language];
    }, [language])

    useEffect(() => {
        setSigns(message);
        setGeneralBar(areAllMessagesEqual(message));
    }, [message])


    return (
        <main style={{ backgroundColor: 'grey' }}>
            <div className='main-header'>
                <h2 className='main-header-title-light'>{dictionary.main.header[language]}</h2>
                <div className="row">
                    <NavButton
                        imgSrc={checkIcon}
                        onClick={() => acknowledge()}
                    />
                    <NavButton
                        imgSrc={resetIcon}
                        onClick={() => handleCommandAuth(() => reset(), 1)}
                    />
                    <NavButton
                        imgSrc={evacuationIcon}
                        onClick={() => handleCommandAuth(() => evacuation(), 1)}
                    />
                    <NavButton
                        imgSrc={simulacrumIcon}
                        onClick={() => handleCommandAuth(() => simulacrum(), 1)}
                    />
                    <NavButton
                        imgSrc={testIcon}
                        onClick={() => setKeypad({
                            ...keypad,
                            show: true,
                            func: () => {
                                test()
                            },
                            accessRequired: 1
                        })}
                    />
                    <NavButton
                        imgSrc={configIcon}
                        onClick={() => setKeypad({
                            ...keypad,
                            show: true,
                            func: () => {
                                navigate('/settings')
                            },
                            accessRequired: 2
                        })}
                    />
                </div>
            </div>
            <div className="bar-container">
                {generalBar &&
                    <LineBar
                        bar={{
                            status: generalBar,
                            lastChange: null
                        }}
                        type='general'
                    />
                }
                {Object.keys(signs)
                    .map((macAddress) => signs[macAddress])
                    .sort((a, b) => new Date(b.lastChange) - new Date(a.lastChange))
                    .map((sign, index) => {
                        return (<>
                            <LineBar key={index} bar={sign} type='sign' />
                            {/* Print inputs */}
                            {Object.keys(sign.inputs)
                                .map((inputKey) => sign.inputs[inputKey])
                                .filter((input) => input.status !== 'OFF' && input.status !== 'disconnect')
                                .sort((a, b) => new Date(b.lastChange) - new Date(a.lastChange))
                                .map((input) => {
                                    return (
                                        <LineBar
                                            bar={{ id: input.id, name: `${sign.name} - ${input.name}`, status: input.status, lastChange: input.lastChange }}
                                            type='input'
                                        />
                                    );
                                })}
                        </>)
                    })}
            </div>
        </main >
    );
}

export default Main;
