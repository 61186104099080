import React, { useEffect } from "react";
import Favicon from "react-favicon";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { fetchGeneral } from "./services/general";
import { useGeneral } from "./contexts/general-context";
import NavBar from "./components/NavBar";
import Main from "./pages/Main";
import EvacRole from "./pages/EvacRole";
import Settings from "./pages/Settings";
import General from "./pages/General";
import Commands from "./pages/Commands";
import Users from "./pages/Users";
import Signs from "./pages/Signs";
import SignManage from "./pages/SignManage";
import SignForm from "./pages/SignForm";
import SignListInputs from "./pages/SignListInputs";
import EvacRoleForm from "./pages/EvacRoleForm";
import SignListCameras from "./pages/SignListCameras";
import CameraForm from "./pages/CameraForm";
import InputManage from "./pages/InputManage";
import InputListCameras from "./pages/InputListCameras";
import SignInputForm from "./pages/SignInputForm";
import SignEvacuationForm from "./pages/SignEvacuationForm";
import SignSimulationForm from "./pages/SignSimulationForm";
import Test from "./pages/Test";
import UsersForm from "./pages/UsersForm";
import Telegram from "./pages/Telegram";
import Reports from "./pages/Reports";
// import Backups from "./pages/Backups";

import HeadIcon from "./images/headicon.jpg";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setGeneralData } = useGeneral()

  useEffect(() => {
    if (location.pathname.includes('/settings')) {
      if (window.localStorage.getItem('token') === null) {
        navigate('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const init = async () => {
      const { name, manual } = await fetchGeneral();
      setGeneralData({ name: name, manual: manual });
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Favicon url={HeadIcon} />
      <NavBar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/sign/:id/evac-role" element={<EvacRole />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/general" element={<General />} />
        <Route path="/settings/commands" element={<Commands />} />
        <Route path="/settings/users" element={<Users />} />
        <Route path="/settings/signs" element={<Signs />} />
        <Route path="/settings/signs/register" element={<SignForm />} />
        <Route path="/settings/signs/:id/manage" element={<SignManage />} />
        <Route path="/settings/signs/:id/evac-role" element={<EvacRoleForm />} />
        <Route path="/settings/signs/:id/cameras" element={<SignListCameras />} />
        <Route path="/settings/signs/:id/cameras/:cameraNumber" element={<CameraForm />} />
        <Route path="/settings/signs/:id/general" element={<SignForm />} />
        <Route path="/settings/signs/:id/inputs" element={<SignListInputs />} />
        <Route path="/settings/signs/:id/inputs/:inputNumber/manage" element={<InputManage />} />
        <Route path="/settings/signs/:id/inputs/:inputNumber/cameras" element={<InputListCameras />} />
        <Route path="/settings/signs/:id/inputs/:inputNumber/cameras/:cameraNumber" element={<CameraForm />} />
        <Route path="/settings/signs/:id/inputs/:inputNumber/general" element={<SignInputForm />} />
        <Route path="/settings/signs/:id/evac" element={<SignEvacuationForm />} />
        <Route path="/settings/signs/:id/simu" element={<SignSimulationForm />} />
        <Route path="/settings/test" element={<Test />} />
        <Route path="/settings/users/:id" element={<UsersForm />} />
        <Route path="/settings/telegram" element={<Telegram />} />
        <Route path="/settings/reports" element={<Reports />} />
        {/* <Route path="/settings/backup" element={<Backups />} /> */}
      </Routes>
    </div >
  );
}

export default App;
