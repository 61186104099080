import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";

import { dictionary } from "../dictionary";

import SaveIcon from "../images/save.svg";
import XRedIcon from "../images/xred.svg";
import { editCamera, fetchCamera } from "../services/cameras";





const CameraForm = () => {
    const [camera, setCamera] = useState({ enable: false, url: '' });
    const [cameraType, setCameraType] = useState(null);
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { id, inputNumber, cameraNumber } = useParams();

    const formik = useFormik({
        initialValues: camera,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setLoader(true);
                await editCamera(values.id, cameraType, values);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.success.header[language],
                    content: dictionary.modals.alert.default.success.content[language],
                    func: () => {
                        navigate(state.redirectUrl);
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                setLoader(false)
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    })

    useEffect(() => {
        if (inputNumber) {
            setCameraType('input');
        } else {
            setCameraType('sign');
        }
        document.title = `Camara ${cameraNumber}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const camera = await fetchCamera(state.camId, cameraType);
            setCamera(camera);
        }
        if (cameraType) {
            setLoader(true);
            fetchData();
            setLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraType]);

    return (
        <>

            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Camara {cameraNumber}</h2>
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='input-group'>
                            <span>
                                <input
                                    className="input-input"
                                    type="checkbox"
                                    checked={formik.values.enable}
                                    onChange={(e) => formik.setFieldValue('enable', e.target.checked)}
                                />
                                Habilitado
                            </span>
                        </div>
                        <div className='input-group'>
                            <label htmlFor="name">
                                URL de la camara
                            </label>
                            <input
                                id="name"
                                className="input"
                                placeholder='URL de la camara'
                                name="url"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.url}
                            />
                        </div>
                        <button
                            className='button'
                            type="submit"
                        >
                            <img src={SaveIcon} alt="" className='icon' />
                            {dictionary.common.save[language]}
                        </button>
                    </form>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(state.redirectUrl)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default CameraForm;
