import React, { createContext, useContext, useState } from 'react';

const ModalsContext = createContext();

const ModalsProvider = ({ children }) => {
    const [factoryReset, setFactoryReset] = useState({ show: false });
    const [restoreModal, setRestoreModal] = useState({
        show: false,
        general: 'loading',
        lines: 'loading',
        inputs: 'loading',
        outputsTimers: 'loading',
        users: 'loading',
        network: 'loading',
        telegram: 'loading'
    });
    const [command, setCommand] = useState({
        show: false,
        number: null,
        title: ''
    });
    const [keypad, setKeypad] = useState({
        show: false,
        userSelected: 0,
        code: '',
        func: () => { },
        accessRequired: 2
    });
    const [alert, setAlert] = useState({
        show: false,
        title: '',
        content: '',
        func: () => { }
    });
    const [loader, setLoader] = useState(false);

    return (
        <ModalsContext.Provider value={{
            factoryReset, setFactoryReset,
            restoreModal, setRestoreModal,
            command, setCommand,
            keypad, setKeypad,
            alert, setAlert,
            loader, setLoader
        }}>
            {children}
        </ModalsContext.Provider>
    );
};

const useModals = () => {
    return useContext(ModalsContext);
};

export { ModalsProvider, useModals };