import React, { createContext, useContext, useEffect, useState } from 'react';
import { env } from '../config';


const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {

    const [ws, setWs] = useState(null);
    const [message, setMessage] = useState({});
    const [status, setStatus] = useState('DISCONNECTED');


    useEffect(() => {
        const connectToWebSocket = () => {
            const socket = env === 'production' ? new WebSocket(`${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.hostname}/ws`) : new WebSocket(`${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.hostname}:8080`);

            socket.onopen = () => {
                console.log('Connected to WebSocket');
                setStatus('CONNECTED');
            };

            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setMessage(data);
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                setStatus('ERROR');
            };

            socket.onclose = () => {
                console.log('Disconnected from WebSocket');
                setStatus('DISCONNECTED');

                setTimeout(() => {
                    console.log('Attempting to reconnect...');
                    connectToWebSocket();
                }, 2000);
            };

            setWs(socket);

            return () => {
                socket.close();
            };
        }
        connectToWebSocket();
    }, []);

    const sendMessage = (message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(message);
        }
    };
    return (
        <WebSocketContext.Provider value={{ ws, status, message, sendMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};

const useWebSocket = () => {
    return useContext(WebSocketContext);
};

export { WebSocketProvider, useWebSocket };
