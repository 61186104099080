import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiDomain } from '../config';
import axios from 'axios';

const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {
    const [localTime, setLocalTime] = useState(null);
    const [language, setLanguage] = useState('es');
    const [generalData, setGeneralData] = useState({ name: '', manual: '', extinguisher: '' });

    useEffect(() => {
        const syncClock = async () => {
            try {
                const response = await axios(`${apiDomain}/api/general/datetime`);
                setLocalTime(new Date(response.data.date).getTime());
            } catch (error) {
                setLocalTime(new Date().getTime());
                console.log('Error: ', error)
            }
        };

        const updateClock = () => {
            setLocalTime(prevTime => prevTime + 500); // should be 1000 but in effect 500 is 1s idk why
        };
        const fetchLanguage = async () => {
            try {
                const response = await axios(`${apiDomain}/api/general/language`);
                const languageResponse = response.data.language || 'es';
                setLanguage(languageResponse);
                window.localStorage.setItem('language', languageResponse);
            } catch (error) {
                console.log(error)
            }
        };

        fetchLanguage();
        syncClock()
        updateClock()
        setInterval(syncClock, 1000 * 60 * 60 * 24); // 1 day
        setInterval(updateClock, 1000); // 1 second
    }, []);

    return (
        <GeneralContext.Provider value={{ localTime, language, setLanguage, generalData, setGeneralData }}>
            {children}
        </GeneralContext.Provider>
    );
};

const useGeneral = () => {
    return useContext(GeneralContext);
};

export { GeneralProvider, useGeneral };
