import React from "react";
import { useNavigate } from "react-router-dom";
import { useModals } from "../../contexts/modals-context";
import { useGeneral } from "../../contexts/general-context";
import { dictionary } from "../../dictionary";

// import { ReactComponent as CheckIcon } from "../../images/check.svg";
// import { ReactComponent as XRedIcon } from "../../images/xred.svg";


const RestoreModal = () => {
    const navigate = useNavigate();
    const { language } = useGeneral();
    const { restoreModal, setRestoreModal } = useModals();

    const iconSelector = (status) => {
        if (status === 'success') {
            return (<p style={{ width: '40px', color: 'green' }}>OK</p>);
        } else if (status === 'error') {
            return (<p style={{ width: '40px', color: 'red' }}>ERROR</p>);
        } else {

            return (<div style={{ width: '40px' }}><div className="loader"></div></div>);
        }
    }

    return (
        <div className="modal" style={{ display: restoreModal.show ? 'block' : 'none' }}>
            <div className="modal-content">
                <div>
                    <div>
                        <h3 className="modal-header">{dictionary.modals.restore.header[language]}</h3>
                    </div>
                    <div>
                        <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>{dictionary.modals.restore.content.general[language]}</p>
                            <div className="restore-icon">{iconSelector(restoreModal.general)}</div>
                        </div>
                        <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>{dictionary.modals.restore.content.lines[language]}</p>
                            <div className="restore-icon">{iconSelector(restoreModal.lines)}</div>
                        </div>
                        <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>{dictionary.modals.restore.content.inputs[language]}</p>
                            <div className="restore-icon">{iconSelector(restoreModal.inputs)}</div>
                        </div>
                        <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>{dictionary.modals.restore.content.outputsTimers[language]}</p>
                            <div className="restore-icon">{iconSelector(restoreModal.outputsTimers)}</div>
                        </div>
                        <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>{dictionary.modals.restore.content.accessCodes[language]}</p>
                            <div className="restore-icon">{iconSelector(restoreModal.users)}</div>
                        </div>
                        <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>{dictionary.modals.restore.content.network[language]}</p>
                            <div className="restore-icon">{iconSelector(restoreModal.network)}</div>
                        </div>
                        <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>{dictionary.modals.restore.content.telegram[language]}</p>
                            <div className="restore-icon">{iconSelector(restoreModal.telegram)}</div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ justifyContent: 'center' }}>
                    <button
                        className="navbutton modal-button"
                        onClick={() => {
                            setRestoreModal({ ...restoreModal, show: false });
                            navigate('/');
                            window.location.reload();
                        }}
                        style={{ width: 'auto' }}>
                        {dictionary.common.reset[language]}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RestoreModal;
