import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { useGeneral } from "../contexts/general-context";
import { useModals } from "../contexts/modals-context";
import { dictionary, tabNames } from "../dictionary";
import { apiDomain } from "../config";

import ReportsIcon from "../images/reports.svg";
import XRedIcon from "../images/xred.svg";
import { generateCSV } from "../utils";

function Reports() {
    const [reports, setReports] = useState([])
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const navigate = useNavigate();


    const downloadReport = async () => {
        try {
            let data = [
                ['#', dictionary.config.reports.table.header.datetime[language], 'Descripcion'],
            ];

            reports.forEach((report) => {
                data.push([report.id, report.date, report.description])
            })
            generateCSV(data);
        } catch (error) {
            console.log('Error: ', error)
            setAlert({
                show: true,
                title: dictionary.modals.alert.reports.GETError.header[language],
                content: dictionary.modals.alert.reports.GETError.content[language],
                func: () => { }
            });
        }
    }

    useEffect(() => {
        const fetchReports = async () => {
            try {
                setLoader(true);
                const response = await axios.get(`${apiDomain}/api/reports`)
                setReports(response.data
                    .map(report => ({
                        ...report,
                        date: moment(report.date)
                            .format('DD/MM/YYYY HH:mm:ss')
                    }))
                );
                setLoader(false);
            } catch (error) {
                console.log('Error: ', error)
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.reports.GETError.header[language],
                    content: dictionary.modals.alert.reports.GETError.content[language],
                    func: () => { }
                });
            }
        }
        document.title = tabNames.reports[language];
        fetchReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div class='main-header'>
                    <h2 class='main-header-title-dark'>{tabNames.reports[language]}</h2>
                </div>
                <div style={{ padding: '8px' }}>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    #
                                </th>
                                <th scope="col">
                                    {dictionary.config.reports.table.header.datetime[language]}
                                </th>
                                <th scope="col">
                                    Descripcion
                                </th>
                            </tr>
                        </thead>
                        <tbody id="reports-table-tbody">
                            {reports.map((report) =>
                                <tr>
                                    <th scope="row">{report.id}</th>
                                    <td>{report.date}</td>
                                    <td>{report.description}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </main>
            <footer class="row">
                <button class='navbutton' onClick={() => downloadReport()}>
                    <img src={ReportsIcon} alt="" />
                    <span>{dictionary.config.reports.download[language]}</span>
                </button>
                <button class='navbutton' onClick={() => navigate('/settings')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default Reports;
