import React, { createContext, useContext, useState } from 'react';

const TimersContext = createContext();

const TimersProvider = ({ children }) => {
    const [commandsTimers, setCommandsTimers] = useState([]);

    return (
        <TimersContext.Provider value={{ commandsTimers, setCommandsTimers }}>
            {children}
        </TimersContext.Provider>
    );
};

const useTimers = () => {
    return useContext(TimersContext);
};

export { TimersProvider, useTimers };