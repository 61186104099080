import axios from "axios"
import { apiDomain } from "../config"


export const fetchCamera = async (id, type) => {
    const response = await axios.get(`${apiDomain}/api/cameras/${id}?type=${type}`)
    return (response.data);
}

export const fetchCamerasSign = async (sign) => {
    const response = await axios.get(`${apiDomain}/api/cameras?sign=${sign}`)
    return (response.data);
}

export const fetchCamerasInput = async (input) => {
    const response = await axios.get(`${apiDomain}/api/cameras?input=${input}`)
    return (response.data);
}

export const editCamera = async (id,type, cameraBody) => {
    const response = await axios.put(`${apiDomain}/api/cameras/${id}?type=${type}`, cameraBody)
    return (response.data);
}
